import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import { ContractService } from "@/models";
import qs from "qs";

export enum ContractServiceAdminTags {
  ContractServiceCreate = "ContractServiceCreate",
  ContractServiceUpdate = "ContractServiceUpdate",
  ContractServiceDelete = "ContractServiceDelete",
  ContractServiceIndex = "ContractServiceIndex",
}

@Component
export default class ContractServiceAdminMixin extends Vue {
  createContractService(
    data: Partial<ContractService>
  ): Promise<ContractService> {
    const url = `${SD_BASE_ADMIN_API_URL}/contract_services`;
    return httpStore.request({
      tag: ContractServiceAdminTags.ContractServiceCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<ContractService>;
  }

  getContractServices(page: number | null | string, filter: any) {
    const url = `${SD_BASE_ADMIN_API_URL}/contract_services`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: ContractServiceAdminTags.ContractServiceIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<ContractService[]>;
  }

  updateContractService(
    id: string,
    data: Partial<ContractService>
  ): Promise<ContractService> {
    const url = `${SD_BASE_ADMIN_API_URL}/contract_services/${id}`;

    return httpStore.request({
      tag: ContractServiceAdminTags.ContractServiceUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<ContractService>;
  }

  deleteContract(id: string): Promise<ContractService> {
    const url = `${SD_BASE_ADMIN_API_URL}/contract_services/${id}`;
    return httpStore.request({
      tag: ContractServiceAdminTags.ContractServiceDelete,
      url: url,
      method: "DELETE",
    }) as Promise<ContractService>;
  }
}
