
import { Component, Ref, Mixins, Prop } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import ContractServiceMixin from "@/mixins/http/admin/ContractServiceAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { currentUserStore } from "@/store/typed";
import { ContractService } from "../../models";
import ContractServiceForm, {
  ContractServiceCreateData,
} from "./CreateForm.vue";

@Component({
  components: {
    VModal,
    ContractServiceForm,
  },
})
export default class ContractServiceCreateModal extends Mixins(
  ContractServiceMixin
) {
  @Ref() readonly modal!: VModal;

  @Prop({ required: true }) readonly realmId!: string;
  @Prop({ required: true }) readonly userIds!: string[];

  private contractService: ContractService | null = null;

  private contractServicePayload: ContractServiceCreateData | null = null;

  get actionName() {
    return this.contractService?.id ? "Aggiorna" : "Crea";
  }

  get isUserSuperAdmin() {
    return currentUserStore.user ? currentUserStore.user.isSuperAdmin : false;
  }

  get serviceFilter() {
    return { realm: this.realmId };
  }

  get assigneeFilter() {
    return { ids: this.userIds };
  }

  private onSubmit() {
    if (this.contractService?.id) {
      this.httpUpdateService();
    } else {
      this.httpCreateService();
    }
  }

  private async httpCreateService() {
    const [data, errors] = await safeAsync(
      this.createContractService(this.contractServicePayload)
    );

    if (errors) {
      showErrorToast("Errore durante la creazione della linea di servizio");
      return;
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  private async httpUpdateService() {
    const [data, errors] = await safeAsync(
      this.updateContractService(
        this.contractService.id,
        this.contractServicePayload
      )
    );

    if (errors) {
      showErrorToast("Errore durante la modifica della linea di servizio");
      return;
    }

    showToast("Aggiornamento avvenuta con successo!");
    this.$emit("updated", data);
    this.hide();
  }

  create(contractService: ContractService) {
    this.assignObject(contractService);
    this.modal.show();
  }

  assignObject(contractService: ContractService) {
    this.contractService = contractService;
    if (this.contractService) {
      this.contractServicePayload = {
        executionPlace: contractService.executionPlace,
        id: contractService.id,
        serviceId: contractService.serviceId,
        assigneeId: contractService.assigneeId,
        contractId: contractService.contractId,
        startsAt: contractService.startsAt,
        endsAt: contractService.endsAt,
        days: this.contractService.days,
        projectTitle: this.contractService.projectTitle,
        value: this.contractService.value,
        billingType: this.contractService.billingType,
      };
    }
  }

  edit(contractService: ContractService) {
    this.assignObject(contractService);
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
