
import { Component, Ref, Mixins } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { ContractDocument, ContractService, Task } from "../../models";
import TaskAdminMixin, {
  TaskAdminTags,
} from "@/mixins/http/admin/TaskAdminMixin";
import { VNumericInputApplication } from "@/components/Common/VNumericInput.vue";

import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import CategorySelect from "@/components/ContractDocument/CategorySelect.vue";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import VNumericInput from "@/components/Common/VNumericInput.vue";
import WithErrors from "@/directives/WithErrors";
import { httpStore } from "../../store/typed";
import ContractServiceSelect from "@/components/ContractService/Select.vue";

@Component({
  components: {
    VModal,
    VNumericInput,
    CategorySelect,
    VFileUploader,
    ContractServiceSelect,
    DocumentForm,
  },
  directives: {
    WithErrors,
  },
})
export default class TaskCreateModal extends Mixins(TaskAdminMixin) {
  private task: Task | null = null;

  @Ref() readonly modal!: VModal;

  private contractId: string | null = null;
  private contractService: ContractService | null = null;

  @RegisterHttp(TaskAdminTags.TaskCreate) readonly createRequest!: RequestData;

  open(contractId: string) {
    this.task = Task.empty();
    this.contractId = contractId;
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  get isMeasuredPrice() {
    return this.contractService.isMeasuredPrice;
  }

  get maxEndDate() {
    return this.contractService?.endsAt;
  }

  get minStartDate() {
    return this.contractService?.startsAt;
  }

  onCreated(task: ContractDocument) {
    this.$emit("created", task);
    this.contractService = null;
    this.hide();
  }

  async create() {
    const request = this.createTask(this.task);

    const [data, errors] = await safeAsync(request);
    if (!errors) {
      this.onCreated(data);
      showToast("Task creato!");
    } else {
      showToast("Non è stato possibile creare un task", {
        type: "error",
      });
    }
  }

  get loading() {
    return httpStore.status[this.httpTag]?.loading;
  }

  get currencyUsage() {
    return VNumericInputApplication.CURRENCY;
  }

  get baseUsage() {
    return VNumericInputApplication.BASE;
  }

  onContractServiceChanged(contractService: ContractService) {
    this.contractService = contractService;
    this.task.startDate = contractService.startsAt;
    this.task.endDate = contractService.endsAt;
  }

  get httpTag() {
    return TaskAdminTags.TaskCreate;
  }
  get title() {
    return "Nuovo Task";
  }
}
