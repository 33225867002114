
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import { Contract } from "../../models";
import GanttTask from "@/models/gantt/GanttTask";
import GanttElastic from "gantt-elastic";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import CreateModal from "@/components/Task/CreateModal.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import GanttHeader from "gantt-elastic-header";
import moment from "moment";
import TaskAdminMixin, {
  TaskAdminTags,
} from "@/mixins/http/admin/TaskAdminMixin";
import TaskDetailsModal from "@/components/Task/DetailsModal.vue";

import Throttler from "@/utils/Throttler";
import { currentUserStore } from "../../store/typed";
import ContractServiceAdminMixin from "@/mixins/http/admin/ContractServiceAdminMixin";

export class GanttStepDuration {
  id!: string;
  label!: string;
  zoom!: number;
}

@Component({
  components: {
    GanttElastic,
    CreateModal,
    GanttHeader,
    TaskDetailsModal,
  },
})
export default class ContractGantt extends Mixins(
  TaskAdminMixin,
  ContractServiceAdminMixin
) {
  @Prop({ required: true }) readonly value!: Contract;

  private contract: Contract | null = null;

  @Ref() readonly createTaskModal!: any;
  @Ref() readonly taskDetailsModal!: TaskDetailsModal;

  @RegisterHttp(TaskAdminTags.TaskIndex) readonly getTasksRequedt: RequestData;

  private tasks: GanttTask[] = [];
  private showTaskList = true;
  private stepDuration = "week";
  private zoom = 15;
  private stepDurations: GanttStepDuration[] = [
    { id: "day", label: "Giorno", zoom: 10 },
    { id: "week", label: "Settimana", zoom: 15 },
    { id: "month", label: "Mese", zoom: 24 },
  ];

  private throttler: Throttler = new Throttler(100);

  created() {
    this.contract = this.value;
  }

  onCreated() {
    this.load();
  }

  onTaskProgressUpdated() {
    this.load();
  }

  onTaskDelete() {
    this.load();
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  mounted() {
    this.load();
  }

  private openDetailsModal(event: any) {
    const [type, id] = event.data.id.split("-");

    if (type === "task" && id) {
      this.throttler.run(() => {
        this.taskDetailsModal.open(id, this.contract.id);
      });
    }
  }

  get loading() {
    return this.getTasksRequedt?.loading;
  }

  get filter() {
    return { contract: this.contract?.id };
  }

  get createTaskModalRef() {
    return "createTaskModal";
  }

  openCreateTaskModal() {
    (this.$refs[this.createTaskModalRef] as any).open(this.contract.id);
  }

  async load() {
    await this.loadContractServices();
    await this.loadTasks();
  }

  private async loadContractServices() {
    const [data, errors] = await safeAsync(
      this.getContractServices("all", this.filter)
    );

    if (!errors) {
      this.contract.contractServices = data;
    }
  }

  private async loadTasks() {
    const [data, errors] = await safeAsync(this.getTasks("all", this.filter));

    if (!errors) {
      this.tasks = GanttTask.fillFromContract(this.contract, data);
    }
  }

  changeStepDuration(duration: GanttStepDuration) {
    this.stepDuration = duration.id;
    this.zoom = duration.zoom;
  }

  get options() {
    return {
      taskMapping: {
        progress: "percent",
      },
      maxRows: 100,
      maxHeight: 500,
      row: {
        height: 35,
      },
      times: {
        timeZoom: this.zoom,
        stepDuration: this.stepDuration,
      },

      calendar: {
        hour: {
          display: false,
        },
        month: {
          height: 50,
          display: true,
          long(date: any) {
            return moment(date.$d).format("MMM");
          },
          medium(date: any) {
            return moment(date.$d).format("MMM");
          },
          short(date: any) {
            return moment(date.$d).format("MMM");
          },
        },
        day: {
          height: 50,
          format: {
            long(date: any) {
              return moment(date.$d).format("DD dddd");
            },
            medium(date: any) {
              return moment(date.$d).format("DD dddd");
            },
            short(date: any) {
              return moment(date.$d).format("DD dddd");
            },
          },
        },
      },
      chart: {
        progress: {
          bar: false,
        },
        expander: {
          display: false,
        },
      },
      taskList: {
        display: this.showTaskList,
        expander: {
          straight: false,
        },
        columns: [
          {
            id: 1,
            label: "Nome",
            value: "label",
            width: 150,
            expander: true,
            html: true,
          },
          {
            id: 2,
            label: "Inizio",
            value: (task: GanttTask) => moment(task.start).format("DD/MM/YYYY"),
            width: 78,
          },
          {
            id: 3,
            label: "Fine",
            value: (task: GanttTask) => moment(task.end).format("DD/MM/YYYY"),
            width: 78,
          },
          {
            id: 4,
            label: "Commenti",
            value: (task: GanttTask) => task.commentCount,
            width: 45,
          },
          {
            id: 5,
            value: (task: GanttTask) => `${(task.percent * 100).toFixed(0)}%`,
            label: "%",
            width: 45,
            style: {
              "task-list-header-label": {
                "text-align": "center",
                width: "100%",
              },
              "task-list-item-value-container": {
                "text-align": "center",
                width: "100%",
              },
            },
          },
        ],
      },
      locale: {
        name: "it",
        months: [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre",
        ],
        Now: "Ora",
        "X-Scale": "Zoom-X",
        "Y-Scale": "Zoom-Y",
        "Task list width": "Task list",
        "Before/After": "Expand",
        "Display task list": "Task list",
      },
    };
  }
}
