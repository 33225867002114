import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import { ContractDocument } from "@/models";
import qs from "qs";

export enum ContractDocumentAdminTags {
  DeliverableCreate = "DeliverableCreate",
  ComplianceCheckCreate = "ComplianceCheckCreate",
  ContractDocumentIndex = "ContractDocumentIndex",
  ContractDocumentDestroy = "ContractDocumentDestroy",
  ShareableDocumentCreate = "ShareableDocumentCreate",
}

@Component
export default class ContractDocumentsAdminMixin extends Vue {
  createDeliverable(payload: any): Promise<ContractDocument> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${payload.contractId}/contract_documents/create_deliverable`;

    const data = new FormData();

    if (payload.file) {
      data.append("file", payload.file.file);
    }

    if (payload.contractServiceId) {
      data.append("contractServiceId", payload.contractServiceId);
    }

    data.append("type", payload.type || "");
    data.append("contractId", payload.contractId || "");
    data.append("name", payload.name || "");
    data.append("description", payload.description || "");
    data.append("category", payload.category || "");

    return httpStore.request({
      tag: ContractDocumentAdminTags.DeliverableCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<ContractDocument>;
  }

  createComplianceCheck(payload: any): Promise<ContractDocument> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${payload.contractId}/contract_documents/create_compliance_check`;

    const data = new FormData();

    if (payload.file) {
      data.append("file", payload.file.file);
    }

    data.append("type", payload.type);
    data.append("contractId", payload.contractId);
    data.append("name", payload.name);
    data.append("description", payload.description);
    data.append("categoory", payload.category);

    return httpStore.request({
      tag: ContractDocumentAdminTags.ComplianceCheckCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<ContractDocument>;
  }

  createShareableDocument(payload: any): Promise<ContractDocument> {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${payload.contractId}/contract_documents/create_shareable_document`;

    const data = new FormData();

    if (payload.file) {
      data.append("file", payload.file.file);
    }

    data.append("type", payload.type);
    data.append("contractId", payload.contractId);
    data.append("name", payload.name);
    data.append("description", payload.description);
    data.append("categoory", payload.category);

    return httpStore.request({
      tag: ContractDocumentAdminTags.ShareableDocumentCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<ContractDocument>;
  }

  getContractDocuments(page: number | null, filter: any) {
    const url = `${SD_BASE_ADMIN_API_URL}/contracts/${filter.contract}/contract_documents`;

    const data = {
      page: page,
      filter: filter,
      include: "contract_service.service",
    };

    return httpStore.request({
      tag: ContractDocumentAdminTags.ContractDocumentIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<ContractDocument[]>;
  }

  destroyDocument(id: string) {
    const url = `${SD_BASE_ADMIN_API_URL}/contract_documents/${id}`;

    return httpStore.request({
      tag: ContractDocumentAdminTags.ContractDocumentDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<ContractDocument>;
  }
}
