
import { Component, Ref, Mixins } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Comment, ContractDocument, Task } from "../../models";
import CommentMixin, { CommentMixinTags } from "@/mixins/http/CommentMixin";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import CommentsList from "@/components/Comment/List.vue";
import { currentUserStore } from "../../store/typed";
@Component({
  components: {
    VModal,
    CommentsList,
  },
  directives: {
    WithErrors,
  },
})
export default class CommentModal extends Mixins(CommentMixin) {
  @RegisterHttp(CommentMixinTags.CommentCreate)
  createRequest!: RequestData;

  @Ref() readonly modal!: VModal;

  @Ref() readonly commentList!: any;

  private commentable: Task | ContractDocument | null = null;
  private content: string | null = null;

  open(commentable: Task | ContractDocument) {
    this.commentable = commentable;
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  onCreated(comment: Comment) {
    this.$emit("created", comment);
    this.commentList.addItem(comment);
    this.reset();
  }

  get commentsFilter() {
    return {
      commentable: this.commentable?.id,
      commentableType: "ContractDocument",
    };
  }

  get canCreate() {
    return currentUserStore.currentUser?.isOrganizationUser;
  }

  reset() {
    this.content = null;
  }

  async create() {
    const payload = {
      commentableId: this.commentable?.id,
      commentableType: this.commentable?.modelType,
      content: this.content,
    };

    const [data, errors] = await safeAsync(this.createComment(payload));
    if (!errors) {
      this.onCreated(data);
      showToast("Commento creato");
    } else {
      showToast("Non è stato creare il commento", {
        type: "error",
      });
    }
  }

  get loading() {
    return this.createRequest?.loading;
  }

  get httpTag() {
    return CommentMixinTags.CommentCreate;
  }

  get title() {
    return `Visualizza rilevazioni - ${this.commentable?.name}`;
  }
}
