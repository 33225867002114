
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import ContractService from "../../models/ContractService";
import moment from "moment";
import Contract from "../../models/Contract";
import VIcon from "@/components/VIcon.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import ContractServiceAdminMixin from "@/mixins/http/admin/ContractServiceAdminMixin";
import { showErrorToast } from "@/utils/Toast";
import ConfirmModal from "../Common/VConfirmModal.vue";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {
    VIcon,
    ConfirmModal,
  },
})
export default class ContractServiceItem extends Mixins(
  ContractServiceAdminMixin
) {
  @Prop({ required: true }) readonly contractService!: ContractService;
  @Prop({ required: true }) readonly contract!: Contract;
  @Ref() readonly confirmModal!: ConfirmModal;

  get serviceName() {
    return this.contractService?.service?.name;
  }

  private askForConfirm() {
    this.confirmModal.confirm(
      "Elimina servizio",
      "Eliminando questo servizio dal contratto perderai tutti i task e le valutazioni ad esso collegati. Sei sicuro?",
      ""
    );
  }

  get daysLabel() {
    return this.days == 1 ? "giornata" : "giornate";
  }

  get days() {
    return this.contractService?.days;
  }

  get value() {
    return this.contractService?.value;
  }

  get isAdministrative() {
    return currentUserStore.currentUser.isAdministrative;
  }

  get assigneeFullname() {
    const assignee = this.contractService?.assignee;
    return `${assignee.firstName} ${assignee.lastName}`;
  }

  get startDate() {
    return this.contractService?.startsAt
      ? moment(this.contractService.startsAt)
          .format("DD MMM YYYY")
          .toUpperCase()
      : "";
  }

  get endDate() {
    return this.contractService?.endsAt
      ? moment(this.contractService.endsAt).format("DD MMM YYYY").toUpperCase()
      : "";
  }

  private async deleteContractService() {
    const [data, errors] = await safeAsync(
      this.deleteContract(this.contractService.id)
    );

    if (data) {
      this.$emit("deleted");
    } else {
      showErrorToast("Sì è verificato un errore");
    }
  }
}
