
import { Component, Ref, Mixins } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { ContractDocument } from "../../models";
import ContractDocumentAdminMixin, {
  ContractDocumentAdminTags,
} from "@/mixins/http/admin/ContractDocumentAdminMixin";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import CategorySelect from "@/components/ContractDocument/CategorySelect.vue";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import {
  ContractDocumentPayload,
  ContractDocumentType,
} from "../../models/ContractDocument";
import { httpStore } from "../../store/typed";
import ContractServiceSelect from "@/components/ContractService/Select.vue";
@Component({
  components: {
    VModal,
    CategorySelect,
    VFileUploader,
    DocumentForm,
    ContractServiceSelect,
  },
  directives: {
    WithErrors,
  },
})
export default class ContractDocumentCreateModal extends Mixins(
  ContractDocumentAdminMixin
) {
  private file: any = null;

  private contractId: string | null = null;

  private type: ContractDocumentType | null = null;

  private contractDocument: ContractDocumentPayload | null =
    ContractDocumentPayload.empty();

  @Ref() readonly modal!: VModal;

  upload(type: ContractDocumentType, contractId: string) {
    this.type = type;
    this.contractDocument.type = type;
    this.contractDocument.contractId = contractId;
    this.contractId = contractId;

    (this.modal as any).show();
  }

  get fileName() {
    return this.contractDocument?.file ? this.contractDocument?.file.name : "";
  }

  hide() {
    (this.modal as any).hide();
  }

  get isDeliverable() {
    return this.type === ContractDocumentType.Deliverable;
  }

  get isComplianceCheck() {
    return this.type === ContractDocumentType.ComplianceCheck;
  }

  get isShareable() {
    return this.type === ContractDocumentType.ShareableDocument;
  }

  get contractServiceFilter() {
    return { contract: this.contractId };
  }

  onCreated(contractDocument: ContractDocument) {
    this.$emit("created", contractDocument);
    this.reset();
    this.hide();
  }

  reset() {
    this.contractDocument.name = null;
    this.contractDocument.description = null;
    this.contractDocument.category = null;
  }

  async create() {
    httpStore.register(this.httpTag);

    const request = this.createRequestFromType();

    const [data, errors] = await safeAsync(request);
    if (!errors) {
      this.onCreated(data);
      showToast("Documento legato al contratto");
    } else {
      showToast("Non è stato possibile legare il documento al contratto", {
        type: "error",
      });
    }
  }

  createRequestFromType() {
    switch (true) {
      case this.isDeliverable:
        return this.createDeliverable(this.contractDocument);
      case this.isComplianceCheck:
        return this.createComplianceCheck(this.contractDocument);
      case this.isShareable:
        return this.createShareableDocument(this.contractDocument);
    }
  }

  get loading() {
    return httpStore.status[this.httpTag]?.loading;
  }

  get httpTag() {
    return this.isDeliverable
      ? ContractDocumentAdminTags.DeliverableCreate
      : ContractDocumentAdminTags.ComplianceCheckCreate;
  }
  get title() {
    return "Carica un nuovo documento";
  }
}
