
import { Component, Prop, Ref, Mixins } from "vue-property-decorator";
import { User } from "@/models";
import UserSelect from "@/components/Common/UserSelect.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import ContractsAdminMixin from "@/mixins/http/admin/ContractAdminMixin";
import LoadingButton from "@/components/Common/LoadingButton.vue";
import { UserSelectParams } from "@/mixins/http/UserMixin";

@Component({
  components: { LoadingButton, UserSelect, VModal },
})
export default class ContractUsers extends Mixins(ContractsAdminMixin) {
  @Ref() readonly modal!: VModal;
  @Prop({ required: true }) readonly users!: User[];
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ required: true }) readonly companyIds!: string[];

  private userId: string | null = null;

  detachUserEvent(id: string): void {
    this.$emit("detach-user", { userId: id });
  }

  showModal(): void {
    (this.modal as any).show();
  }

  get hasUsers() {
    return this.users?.length;
  }

  hide(): void {
    (this.modal as any).hide();
  }

  reset() {
    this.userId = null;
  }

  async modalSave() {
    this.$emit("attach-user", { userId: this.userId });
    this.hide();
    this.reset();
  }

  close() {
    this.hide();
    this.reset();
  }

  userSelect(id: string): void {
    this.userId = id;
  }

  get excludedIds(): string[] | number[] {
    return this.users.map((u) => u.id);
  }

  get userSelectFilter(): UserSelectParams {
    return {
      filter: {
        role: "company_user",
        company: this.companyIds,
        not: this.excludedIds,
      },
    };
  }
}
