
import { MilestoneAdminTags } from "@/mixins/http/admin/MilestoneAdminMixin";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import UserSelect from "@/components/User/Select.vue";
import ServiceSelect from "@/components/Service/Select.vue";
import WithErrors from "@/directives/WithErrors";
import BillingTypeSelect from "@/components/Service/BillingTypeSelect.vue";
import { Milestone } from "../../models";
import moment from "moment";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    UserSelect,
    BillingTypeSelect,
    ServiceSelect,
  },
})
export default class MilestoneForm extends Vue {
  @Prop({ required: true }) readonly value!: Milestone;
  @Prop({ required: true }) readonly maxCompletionDate!: Date;

  private item: Milestone = this.value;

  get httpTag() {
    return this.item.id
      ? MilestoneAdminTags.MilestoneUpdate
      : MilestoneAdminTags.MilestoneCreate;
  }

  get canComplete() {
    if (!this.maxCompletionDate) {
      return true;
    }
    return (
      moment(this.maxCompletionDate).toDate().getTime() >=
      moment(this.value.date).toDate().getTime()
    );
  }

  @Watch("item", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.item);
  }
}
