import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import qs from "qs";
import Milestone from "@/models/Milestone";

export enum MilestoneAdminTags {
  MilestoneIndex = "MilestoneIndex",
  MilestoneCreate = "MilestoneCreate",
  MilestoneUpdate = "MilestoneUpdate",
  MilestoneDestroy = "MilestoneDestroy",
}

@Component
export default class MilestoneAdminMixin extends Vue {
  getMilestones(page: number | null | string, filter: any) {
    const url = `${SD_BASE_ADMIN_API_URL}/milestones`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: MilestoneAdminTags.MilestoneIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Milestone[]>;
  }

  createMilestone(data: any): Promise<Milestone> {
    const url = `${SD_BASE_ADMIN_API_URL}/milestones`;

    data.completed = data.completed ? "1" : "0";
    return httpStore.request({
      tag: MilestoneAdminTags.MilestoneCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Milestone>;
  }

  destroyMilestone(id: string | number): Promise<Milestone> {
    const url = `${SD_BASE_ADMIN_API_URL}/milestones/${id}`;

    return httpStore.request({
      tag: MilestoneAdminTags.MilestoneDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Milestone>;
  }

  updateMilestone(data: any): Promise<Milestone> {
    const url = `${SD_BASE_ADMIN_API_URL}/milestones/${data.id}`;
    data.completed = data.completed ? "1" : "0";

    return httpStore.request({
      tag: MilestoneAdminTags.MilestoneUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Milestone>;
  }
}
