
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tag } from "@/models";
@Component({})
export default class TagBadge extends Vue {
  @Prop({ default: false }) readonly selected!: boolean;
  @Prop({ required: true }) readonly tag!: Tag;
  @Prop({ default: true }) readonly toggleEnabled!: boolean;

  get spanClasses() {
    let classes = "";
    if (this.toggleEnabled) {
      classes += " cursor-pointer ";
    }
    if (this.selected) {
      classes += " badge-primary ";
    } else {
      classes += " badge-outline ";
    }
    return classes;
  }

  get iconCode() {
    return this.selected ? "it-close-big" : "it-plus";
  }

  get title() {
    if (!this.toggleEnabled) {
      return null;
    }
    return this.selected ? "Rimuovi Tag" : "Aggiungi Tag";
  }

  emitClick() {
    if (this.toggleEnabled) {
      this.$emit("click");
    }
  }
}
