import Review from "@/models/Review";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";

export enum ReviewTags {
  ReviewUpdate = "ReviewUpdate",
  ReviewIndex = "ReviewIndex",
}

@Component
export default class ReviewMixin extends Vue {
  updateReview(data: Partial<Review>): Promise<Review> {
    const url = `${SD_BASE_API_URL}/reviews/${data.id}`;

    return httpStore.request({
      tag: ReviewTags.ReviewUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Review>;
  }

  getReviews(page: number | string, filter: any) {
    const url = `${SD_BASE_API_URL}/reviews`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: ReviewTags.ReviewIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Review[]>;
  }
}
