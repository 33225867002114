
import { Mixins, Component, Prop, Watch } from "vue-property-decorator";
import { ContractDocument } from "@/models";
import ContractDocumentItem from "@/components/ContractDocument/Item.vue";
import CreateModal from "@/components/ContractDocument/CreateModal.vue";
import { ContractDocumentType } from "../../models/ContractDocument";
import { safeAsync } from "@/utils/AsyncUtil";
import VPaginator from "@/components/Common/VPaginator.vue";
import EmptyView from "@/components/Common/EmptyView.vue";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";
import CategorySelect from "@/components/ContractDocument/CategorySelect.vue";
import ContractDocumentAdminMixin from "@/mixins/http/admin/ContractDocumentAdminMixin";
import { httpStore } from "@/store/typed";
@Component({
  components: {
    CreateModal,
    CategorySelect,
    VPaginator,
    VThrottlerInput,
    EmptyView,
    ContractDocumentItem,
  },
})
export default class ContractDocuments extends Mixins(
  ContractDocumentAdminMixin
) {
  @Prop({ required: true }) title: string[];
  @Prop({ required: true }) icon: string[];
  @Prop({ required: true }) type: ContractDocumentType;
  @Prop({ default: false }) canCreate: boolean[];
  @Prop({ required: true }) contractId: string;
  @Prop({ default: false }) showCategory: boolean;
  @Prop({ default: false }) showDateRange: boolean;

  private page = 0;
  private error = false;
  private term: string | null = null;
  private category: string | null = null;
  private from: string | null = null;
  private to: string | null = null;
  private items: ContractDocument[] = [];

  get createModalRef() {
    return "upload_modal";
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  @Watch("term")
  onTermChanged() {
    this.load();
  }

  @Watch("to")
  onToChanged() {
    this.load();
  }

  @Watch("from")
  onFromChanged() {
    this.load();
  }

  @Watch("category")
  onCategoryChanged() {
    this.load();
  }

  mounted() {
    this.load();
  }

  async load() {
    httpStore.register(this.type);
    const payload: any = {
      contract: this.contractId,
      type: this.type,
    };
    if (this.term) {
      payload.term = this.term;
    }
    if (this.from) {
      payload.from = this.from;
    }
    if (this.to) {
      payload.to = this.to;
    }
    if (this.category && this.category !== "all") {
      payload.category = this.category;
    }
    const [data, errors] = await safeAsync(
      this.getContractDocuments(this.page, payload)
    );
    if (!errors) {
      this.error = false;
      this.items = data;
    } else {
      this.error = true;
    }
  }

  get meta() {
    return httpStore.status[this.type]?.meta;
  }

  get loading() {
    return httpStore.status[this.type]?.loading;
  }

  get id() {
    return this.type === ContractDocumentType.Deliverable
      ? "contract-deliverables"
      : "contract-compliance-checks";
  }

  onCreated(contractDocument: ContractDocument) {
    this.$emit("created", contractDocument);
    this.load();
  }

  openUploadModal() {
    (this.$refs as any)[this.createModalRef].upload(this.type, this.contractId);
  }
}
