
import ContractAdminMixin from "@/mixins/http/admin/ContractAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Vue, Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class ContractCompleteAlert extends Mixins(ContractAdminMixin) {
  @Prop({ required: true }) readonly contractId!: string;

  private async completeContract() {
    const [data, errors] = await safeAsync(this.complete(this.contractId));

    if (errors) {
      showErrorToast("Non è stato possibile completare il contratto");
    } else {
      showToast("Contratto completato correttamente!");
      this.$router.push({ name: "admin_contracts_list" });
    }
  }
}
