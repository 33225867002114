
import { Component, Ref, Mixins } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import TaskDetailsForm from "@/components/Task/DetailsForm.vue";
import Spinner from "@/components/Common/Spinner.vue";
import TaskAdminMixin from "@/mixins/http/admin/TaskAdminMixin";
import { Task } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({
  components: {
    VModal,
    TaskDetailsForm,
    Spinner,
  },
})
export default class DetailsModal extends Mixins(TaskAdminMixin) {
  private task: Task | null = null;

  @Ref() readonly modal!: VModal;

  private contractId: string | null = null;

  private loading = false;

  get isLoading() {
    return this.loading;
  }

  async open(taskId: string, contractId: string) {
    (this.modal as any).show();
    this.contractId = contractId;

    this.load(taskId);
  }

  hide() {
    (this.modal as any).hide();
  }

  private async load(taskId: string) {
    this.loading = true;

    const [data, error] = await safeAsync(this.getTask(taskId));
    if (data) {
      this.task = data;
    }

    this.loading = false;
  }

  onDelete() {
    this.hide();
    this.$emit("delete");
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }
}
