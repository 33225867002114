
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Document } from "../../models";

@Component({
  components: {
    VModal,
    DocumentForm,
  },
})
export default class readOnlyModal extends Vue {
  @Prop({ required: true }) readonly initiativeId!: string;
  @Prop({ default: true }) readonly readOnly!: boolean;

  @Ref() readonly modal!: VModal;
  @Ref() readonly documentForm!: DocumentForm;

  private document: Document | null = null;

  get requirementsPlanDocument() {
    return this.type === "requirementsPlans";
  }

  get operativePlanDocument() {
    return this.type === "operativePlans";
  }

  get workPlanDocument() {
    return this.type === "workPlans";
  }

  get documentUrl() {
    return this.document?.fileUrl;
  }

  get executiveDocument() {
    return this.type === "executiveContracts";
  }

  get title() {
    const action = this.readOnly ? "Visualizza" : "Aggiorna";

    if (this.requirementsPlanDocument) {
      return `${action} piano dei fabbisogni`;
    } else if (this.operativePlanDocument) {
      return `${action} piano operativo`;
    } else if (this.executiveDocument) {
      return `${action} contratto esecutivo`;
    } else if (this.workPlanDocument) {
      return `${action} piano dei lavori generale`;
    }

    return `${action} un documento`;
  }

  show(document: Document) {
    this.document = document;
    (this.modal as any).show();
  }

  get type() {
    return (this.document as any)?.initiativeStatus;
  }

  hide() {
    (this.modal as any).hide();
  }

  onAdd() {
    this.$emit("add", (this.document as any).initiativeStatus);
    this.hide();
  }

  private onUpload() {
    this.documentForm.submit();
  }
}
