
import { Vue, Component, Ref } from "vue-property-decorator";
import InitiativeList from "@/components/Initiative/List.vue";
import PageSection from "@/components/Common/PageSection.vue";
import { currentUserStore, zoneStore } from "@/store/typed";
import InitiativeModal from "@/components/Initiative/Modal.vue";
import OrganizationSelect from "@/components/Organization/Select.vue";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";

@Component({
  components: {
    InitiativeList,
    PageSection,
    InitiativeModal,
    OrganizationSelect,
    VThrottlerInput,
  },
})
export default class Initiatives extends Vue {
  private showCompletedInitiatives = true;

  @Ref() readonly initiativeModal!: InitiativeModal;
  @Ref() readonly initiativeList!: InitiativeList;

  private organizationId: string | null = null;
  private initiativeTerm: string | null = null;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }

  get initiativeStatus() {
    return this.showCompletedInitiatives ? null : "closed";
  }

  get filter() {
    const organization = this.organization === "all" ? null : this.organization;

    return {
      organization: organization,
      term: this.initiativeTerm,
      step: "in_progress",
    };
  }

  get realmFilter() {
    return { realm: zoneStore.realm?.id || "" };
  }

  get title() {
    return this.isAdmin ? "Gestione Iniziative" : "Contratti";
  }

  get organization() {
    return this.organizationId;
  }

  private openCreateInitiativeModal() {
    this.initiativeModal.show();
  }

  private reloadList() {
    this.initiativeList.load();
  }

  private onOrganizationSelected(id: string) {
    this.organizationId = id;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }
}
