import { ContractDocument, Task } from "..";
import Contract from "../Contract";
import ContractService from "../ContractService";

export default class GanttTask {
  id!: string;
  type!: string;
  label!: string;
  parentId?: string;
  days?: number;
  value?: number;
  percent!: number;
  duration!: number;
  commentCount?: number;
  start!: Date;
  end!: Date;
  collapsed?: boolean;
  style?: any;
  dependentOn?: number[];

  //TODO: refactor -> create a store with contract update

  static computeTotalProgress(tasks: GanttTask[]): void {
    const contractTask = tasks.find((o: GanttTask) =>
      o.id.startsWith("contract-")
    );
    const contractServiceTasks = tasks.filter((o: GanttTask) =>
      o.id.startsWith("contract-service-")
    );
    const leafTasks = tasks.filter((o: GanttTask) => o.id.startsWith("task-"));

    // for(const contractServiceTask of contractServiceTasks){
    //   contractServiceTask.percent = this.computePercentage(leafTasks, contractServiceTask)
    // }

    // contractTask.percent = this.computePercentage(contractServiceTasks, contractTask);

    contractTask.percent =
      contractServiceTasks
        .map((o: GanttTask) => o.percent)
        .reduce(
          (a: number | string, b: number | string) =>
            parseFloat(a.toString()) + parseFloat(b.toString()),
          0
        ) / contractServiceTasks.length || 0;
  }

  static computePercentage(tasks: GanttTask[], parent: GanttTask): number {
    const leafs = tasks.filter((o: GanttTask) => o.parentId === parent.id);
    if (leafs.length === 0) {
      return 0;
    }

    const tasksDays = leafs
      .map((o: GanttTask) => o.percent * o.days)
      .reduce((a: number, b: number) => a || 0 + b || 0, 0);
    return tasksDays / parent.days;
  }

  static fillFromContract(contract: Contract, tasks: Task[]): GanttTask[] {
    const ganttTasks = [this.fromContract(contract)];
    const contractServicesTasks = this.fromContractServices(contract);
    const simpleTasks = this.fromTasks(tasks);

    if (contractServicesTasks.length) {
      ganttTasks.push(...contractServicesTasks);
    }
    if (simpleTasks.length) {
      ganttTasks.push(...simpleTasks);
    }
    this.computeTotalProgress(ganttTasks);

    return ganttTasks;
  }

  static fromContract(contract: Contract): GanttTask {
    const task = new GanttTask();
    task.id = `contract-${contract.id}`;
    task.label = contract.initiative.name;
    task.duration = contract.duration;
    task.value = contract.value;
    task.days = contract.days;
    task.start = contract.startDate;
    task.end = contract.endDate;
    task.type = "project";
    task.percent = contract.progress;
    task.style = {
      base: {
        fill: "#0066CC",
        stroke: "#FFFFFF",
      },
    };
    return task;
  }

  static fromContractServices(contract: Contract): GanttTask[] {
    const ganttTasks = [];
    for (let i = 0; i < contract.contractServices.length; i++) {
      const contractService = contract.contractServices[i];
      const task = new GanttTask();
      task.id = `contract-service-${contractService.id}`;
      task.label = contractService.service.name;
      task.duration = contractService.duration;
      task.parentId = `contract-${contract.id}`;
      task.start = contractService.startsAt;
      task.end = contractService.endsAt;
      task.value = contractService.value;
      task.days = contractService.days;
      task.percent = contractService.progress;
      task.type = "task";
      task.style = {
        base: {
          fill: "#0bd9d2",
          stroke: "#FFFFFF",
        },
      };
      ganttTasks.push(task);
    }
    return ganttTasks;
  }

  static fromTasks(tasks: Task[]): GanttTask[] {
    const ganttTasks = [];
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      const ganttTask = new GanttTask();
      ganttTask.id = `task-${task.id}`;
      ganttTask.label = task.name;
      ganttTask.commentCount = task.comments.length;
      ganttTask.duration = task.duration;
      ganttTask.parentId = `contract-service-${task.contractServiceId}`;
      ganttTask.start = task.startDate as Date;
      ganttTask.end = task.endDate as Date;
      ganttTask.percent = task.progress;
      ganttTask.value = task.value;
      ganttTask.days = task.days;
      ganttTask.type = "task";
      ganttTask.style = {
        base: {
          fill: "#918deb",
          stroke: "#FFFFFF",
        },
      };
      ganttTasks.push(ganttTask);
    }
    return ganttTasks;
  }
}
