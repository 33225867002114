
import { Component, Ref, Vue } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Initiative, Milestone } from "../../models";
import { currentUserStore } from "../../store/typed";
import Initiatives from "../ProjectManagement/Initiatives.vue";
@Component({
  components: {
    VModal,
  },
})
export default class CommentModal extends Vue {
  @Ref() readonly modal!: VModal;

  private milestone: Milestone | null = null;
  private initiative: Initiative | null = null;

  open(milestone: Milestone, initiative: Initiative) {
    this.milestone = milestone;
    this.initiative = initiative;
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  get title() {
    return `Digital Path`;
  }
}
