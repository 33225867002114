import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "../../utils/Constants";
import * as qs from "qs";
import { InitiativeTag, Tag } from "@/models/index";

export enum InitiativeTagMixinTags {
  InitiativeTagCreate = "InitiativeTagCreate",
  InitiativeTagDelete = "InitiativeTagDelete",
  InitiativeTagIndex = "InitiativeTagIndex",
}

@Component
export default class InitiativeTagMixin extends Vue {
  getInitiativeTags(initiativeId?: string) {
    const url = `${SD_BASE_API_URL}/initiative_tags`;
    const data = {
      filter: {
        initiative: initiativeId,
      },
    };
    return httpStore.request({
      tag: InitiativeTagMixinTags.InitiativeTagIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<InitiativeTag[]>;
  }

  createInitiativeTag(initiativeId: string, tagId: string) {
    const url = `${SD_BASE_API_URL}/initiative_tags`;
    const data = {
      initiativeId: initiativeId,
      tagId: tagId,
    };
    return httpStore.request({
      tag: InitiativeTagMixinTags.InitiativeTagCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<InitiativeTag>;
  }

  deleteInitiativeTag(initiativeTagId: string) {
    const url = `${SD_BASE_API_URL}/initiative_tags/${initiativeTagId}`;
    return httpStore.request({
      tag: InitiativeTagMixinTags.InitiativeTagDelete,
      url: url,
      method: "DELETE",
    }) as Promise<InitiativeTag>;
  }
}
