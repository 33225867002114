
import { Component, Prop, Mixins } from "vue-property-decorator";
import ReviewMixin from "@/mixins/http/ReviewMixin";
import { Review } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import VIcon from "@/components/VIcon.vue";
import ReviewBox from "@/components/Review/Box.vue";
import { showErrorToast } from "@/utils/Toast";

@Component({
  components: {
    VIcon,
    ReviewBox,
  },
})
export default class ContractReviews extends Mixins(ReviewMixin) {
  @Prop({ required: true }) readonly contractId!: string;
  @Prop({ required: true }) readonly title!: string[];
  @Prop({ required: true }) readonly icon!: string[];
  @Prop({ default: false }) readonly disabled!: boolean;

  private reviews: Review[] = [];

  created() {
    this.load();
  }

  iconCode(index: number, vote: number) {
    return index > vote ? "it-star-outline" : "it-star-full";
  }

  private async load() {
    const filters = {
      page: {
        size: "all",
      },
      filter: {
        contract: this.contractId,
      },
    };

    const [data, errors] = await safeAsync(
      this.getReviews(filters.page.size, filters.filter)
    );
    if (data) {
      this.reviews = data;
    }
  }

  onUpdated(review: Review) {
    const index = this.reviews.findIndex((o: Review) => o.id === review.id);
    this.$set(this.reviews, index, review);
  }

  get legends() {
    return Review.legends;
  }

  private async update(review: Review) {
    const [data, errors] = await safeAsync(this.updateReview(review));

    if (!errors) {
      this.onUpdated(data as Review);
    } else {
      showErrorToast("Sì è verificato un errore");
    }
  }
}
