
import { Vue, Component, Prop } from "vue-property-decorator";
import { Review } from "@/models";
import StarRating from "@/components/Review/StarRating.vue";
import ReviewMetadata from "../../models/ReviewMetadata";

@Component({
  components: {
    StarRating,
  },
})
export default class ReviewBox extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ required: true }) readonly value!: Review;
  @Prop({ required: true }) readonly legends!: Array<{
    vote: number;
    label: string;
  }>;

  private review: Review = this.value;

  get label() {
    if (this.review.isContractServiceReview) {
      return "Valutazione servizio";
    } else {
      return "Valutazione";
    }
  }

  onRated(entry: ReviewMetadata, vote: number) {
    entry.vote = vote;
    this.$emit("update", this.review);
  }

  rowClass(entry: ReviewMetadata) {
    if (entry.id === "total") {
      return "lightgrey-bg-a1";
    }
  }

  legend(vote: number) {
    return this.legends.find((o: any) => o.vote === vote)?.label || "";
  }
}
