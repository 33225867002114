
import moment from "moment";
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Milestone } from "../../models";

const circleWidth = 30;
const barHeight = 8;
const circleBorderWidth = 8;
@Component({})
export default class DigitalPath extends Vue {
  @Prop({ required: true }) readonly values!: Milestone[];

  private milestones: Milestone[] = this.values || [];

  @Ref() readonly digitalPath!: any;

  private digitalPathWidth = 0;

  mounted() {
    this.digitalPathWidth = (this.$refs.digitalPath as any).clientWidth - 150;
  }

  @Watch("values")
  onValuesChanged(milestones: Milestone[]) {
    this.milestones = milestones;
  }

  get orderedMilestones() {
    return this.milestones.sort((a: Milestone, b: Milestone) => {
      var dateA = new Date(a.date).getTime();
      var dateB = new Date(b.date).getTime();
      return dateA > dateB ? 1 : -1;
    });
  }

  slicedTitle(milestone: Milestone) {
    return milestone.title?.length > 10
      ? `${milestone.title.slice(0, 10)}...`
      : milestone.title;
  }

  get rightPathStyle() {
    if (this.milestones.length === 1) {
      return {
        width: "100%",
        "margin-top": `${circleWidth / 2 - barHeight / 2}px`,
        ...this.milestonePathStyle,
      };
    }
    return {};
  }

  get totalTimeSpan() {
    return this.deltaTime(0, this.milestones.length - 1);
  }

  deltaTime(index1: number, index2: number) {
    const first = this.orderedMilestones[index1];
    const last = this.orderedMilestones[index2];
    const delta =
      moment(last.date).toDate().getTime() -
      moment(first.date).toDate().getTime();
    return Math.abs(delta);
  }

  get milestoneActiveStyle() {
    return { height: `${circleWidth}px`, width: `${circleWidth}px` };
  }

  get milestonePathStyle() {
    return { height: `${barHeight}px` };
  }

  get milestoneStyle() {
    return {
      height: `${circleWidth}px`,
      "border-width": `${circleBorderWidth}px`,
      width: `${circleWidth}px`,
    };
  }

  get milestoneContainerStyle() {
    return { "max-width": `${circleWidth}px !important` };
  }

  leftPathStyle(index: number) {
    let width = 0;
    if (this.milestones.length === 2) {
      width = this.digitalPathWidth - 2 * circleWidth;
    } else {
      const delta = this.deltaTime(index, index - 1);
      const percentage = this.totalTimeSpan / delta;
      width = this.digitalPathWidth / percentage - circleWidth;
      if (width <= 50) {
        width = 200;
      }
    }

    return {
      width: `${width}px`,
      "margin-top": `${circleWidth / 2 - barHeight / 2}px`,
      ...this.milestonePathStyle,
    };
  }

  editMilestone(milestone: Milestone) {
    this.$emit("edit", milestone);
  }
}
