
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import EstimateRequestsList from "@/components/EstimateRequest/List.vue";
import { Contract } from "../../models";

@Component({
  components: {
    EstimateRequestsList,
  },
})
export default class ContractEstimateRequests extends Vue {
  @Prop({ required: true }) readonly contract!: Contract;
}
