
import { Component, Mixins, Ref } from "vue-property-decorator";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";

import ContractsAdminMixin, {
  ContractAdminTags,
} from "@/mixins/http/admin/ContractAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { Contract, Initiative, User } from "@/models";
import PageSection from "@/components/Common/PageSection.vue";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import ContractRecap from "@/components/Contract/Recap.vue";
import ContractUsers from "@/components/Contract/Users.vue";
import UserMixin from "@/mixins/http/UserMixin";
import Services from "@/components/Contract/Services.vue";
import ContractServiceModal from "@/components/ContractService/Modal.vue";
import ContractCompanies from "@/components/Contract/Companies.vue";
import ContractReviews from "@/components/Contract/Reviews.vue";
import CreateDocumentModal from "@/components/Document/CreateModal.vue";
import Reviews from "@/components/Contract/Reviews.vue";
import Milestones from "@/components/Contract/Milestones.vue";
import InteractiveMap from "@/components/Contract/InteractiveMap.vue";
import {
  ContractCompany,
  ContractService,
  InitiativeOrganization,
} from "../../models";
import { currentUserStore } from "../../store/typed";
import ContractDocuments from "@/components/Contract/Documents.vue";
import InitiativeOrganizations from "@/components/Initiative/Organizations.vue";
import ContractDocument, {
  ContractDocumentType,
} from "../../models/ContractDocument";
import ContractGantt from "@/components/Contract/Gantt.vue";
import ContractEstimateRequests from "@/components/Contract/EstimateRequests.vue";
import ContractCompleteAlert from "@/components/Contract/CompleteAlert.vue";

const tag = ContractAdminTags.ContractShow;

@Component({
  components: {
    Reviews,
    Milestones,
    ContractReviews,
    ContractDocuments,
    StickyMenu,
    ContractEstimateRequests,
    ContractGantt,
    ContractUsers,
    InitiativeOrganizations,
    ContractRecap,
    InteractiveMap,
    PageSection,
    Services,
    ContractServiceModal,
    ContractCompanies,
    CreateDocumentModal,
    ContractCompleteAlert,
  },
})
export default class ContractShow extends Mixins(
  ContractsAdminMixin,
  InitiativeMixin,
  UserMixin
) {
  @RegisterHttp(tag) readonly showRequest!: RequestData;

  @Ref() readonly reviews: any;
  @Ref() readonly gantt: ContractGantt;

  private error = false;
  private contract: Contract | null = null;
  private initiative: Initiative | null = null;
  private users: User[] | null = null;
  private loading = false;
  private savingRuac = false;
  private manager: User | null = null;
  private fullscreen = false;

  get initiativeItem(): Initiative {
    return this.initiative;
  }

  get isContractCompletable(): boolean {
    return this.contract?.expired && !this.contract?.isCompleted;
  }

  onServiceDeleted(): void {
    this.load();
    this.reviews.load();
    this.gantt.load();
  }

  onOrganizationCreated(item: InitiativeOrganization): void {
    this.initiative.initiativeOrganizations.push(item);
  }

  onOrganizationDestroyed(id: string): void {
    const index = this.initiative.initiativeOrganizations.findIndex(
      (o: InitiativeOrganization) => o.id === id
    );
    this.initiative.initiativeOrganizations.splice(index, 1);
  }

  private links: StickyMenuLink[] = [
    { section: "#contract-recap", icon: "it-file", name: "Scheda contratto" },
    {
      section: "#contract-milestones",
      icon: "it-map-marker-plus",
      name: "Digital Path",
      hide: !this.isAdministrative,
    },
    {
      section: "#contract-companies",
      icon: "it-folder",
      name: "Composizione RTI",
      hide: !this.isAdministrative,
    },
    {
      section: "#contract-users",
      icon: "it-user",
      name: "Utenti gestori",
      hide: !this.isAdministrative,
    },
    {
      section: "#initiative-organizations",
      icon: "it-user",
      name: "Gestione amministrazioni secondarie",
      hide: !this.isAdministrative,
    },
    {
      section: "#contract-estimate-requests",
      icon: "it-inbox",
      name: "Richieste di stima",
    },
    { section: "#contract-gantt", icon: "it-calendar", name: "Gantt" },
    {
      section: "#contract-deliverables",
      icon: "it-files",
      name: "Deliverable iniziativa",
    },
    {
      section: "#contract-compliance-checks",
      icon: "it-check-circle",
      name: "Verifiche di conformità",
    },
    {
      section: "#contract-reviews",
      icon: "it-star-outline",
      name: "Valutazione del servizio",
    },
    {
      section: "#interactive-map",
      icon: "it-share",
      name: "Hub del riuso",
    },
  ];

  get isMobile(): boolean {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get contractServices(): ContractService[] {
    return this.contract?.contractServices;
  }

  get deliverableType(): string {
    return ContractDocumentType.Deliverable;
  }

  onDocumentCreated(contractDocument: ContractDocument): void {
    if (contractDocument.documentType === this.deliverableType) {
      this.contract.deliverables.unshift(contractDocument);
    } else if (contractDocument.documentType === this.shareableDocumentType) {
      this.contract.shareableDocuments.unshift(contractDocument);
    } else {
      this.contract.complianceChecks.unshift(contractDocument);
    }
  }

  get complianceCheckType(): string {
    return ContractDocumentType.ComplianceCheck;
  }

  get shareableDocumentType(): string {
    return ContractDocumentType.ShareableDocument;
  }

  contractCompanyAttached(contractCompany: ContractCompany): void {
    this.contract.contractCompanies.push(contractCompany);
  }

  onServiceCreated(added: ContractService): void {
    this.contract.contractServices.push(added);
    this.reviews.load();
    this.gantt.load();
  }

  onServiceUpdated(updated: ContractService): void {
    const index = this.contract.contractServices.findIndex(
      (o: ContractService) => o.id === updated.id
    );
    this.$set(this.contract.contractServices, index, updated);
    this.gantt.load();
  }

  contractCompanyRemoved(contractCompany: ContractCompany): void {
    this.contract.contractCompanies = this.contract.contractCompanies.filter(
      (o: ContractCompany) => o.id !== contractCompany.id
    );
  }

  get defaultService(): ContractService {
    const defaultContractService = new ContractService();
    defaultContractService.contractId = this.contract.id;
    return defaultContractService;
  }

  get contractCompanies(): ContractCompany[] {
    return this.contract?.contractCompanies;
  }

  get id(): string {
    return this.$route.params.id;
  }

  get projectValue(): string {
    return this.contract?.value?.toString();
  }

  get userIds(): string[] {
    return this.contract.contractUsers.map((u) => u.userId);
  }

  mounted(): void {
    this.load();
  }

  async load(): Promise<void> {
    const [data, errors] = await safeAsync(this.getContract(this.id));
    if (!errors) {
      this.contract = data;
      await this.loadInitiative();
      if (this.isAdministrative) {
        await this.loadUsers();
        await this.loadManager();
      }
    } else {
      this.error = true;
    }
  }

  get companyIds(): string[] {
    return (
      this.contract?.contractCompanies?.map(
        (o: ContractCompany) => o.companyId
      ) || []
    );
  }

  private async loadManager() {
    const [data, errors] = await safeAsync(
      this.getUser(this.contract.managerId)
    );

    if (!errors) {
      this.manager = data;
    }
  }

  get isAdministrative(): boolean {
    return currentUserStore.currentUser?.isAdministrative;
  }

  private async loadInitiative() {
    const [init_data, init_errors] = await safeAsync(
      this.getInitiative(this.contract.initiative.id)
    );

    if (!init_errors) {
      this.initiative = init_data;
    } else {
      this.error = true;
    }
  }

  private async loadUsers() {
    const filter = {
      filter: {
        ids: this.userIds.length > 0 ? this.userIds : null,
      },
    };
    const [data, errors] = await safeAsync(this.getUsers(filter));
    if (!errors) {
      this.users = data;
    } else {
      this.error = true;
    }
  }

  async attachUserEvent(param: { userId: string }): Promise<void> {
    this.loading = true;
    await this.attachUser(this.contract.id, param.userId);
    await this.load();
    this.loading = false;
  }

  async detachUserEvent(param: { userId: string }): Promise<void> {
    this.loading = true;
    await this.detachUser(this.contract.id, param.userId);
    await this.load();
    this.loading = false;
  }

  async updateRuac(param: string): Promise<void> {
    this.savingRuac = true;
    await this.updateContract(this.contract.id, param);
    await this.load();
    this.savingRuac = false;
  }

  onDocumentUpdated(): void {
    this.loadInitiative();
  }
}
