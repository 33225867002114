
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import CommentsModal from "@/components/Comment/Modal.vue";
import { ContractDocument, Comment } from "../../models";
import VAccordion from "@/components/Common/VAccordion.vue";
import { currentUserStore } from "../../store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import ContractDocumentsAdminMixin, {
  ContractDocumentAdminTags,
} from "@/mixins/http/admin/ContractDocumentAdminMixin";
import { showToast } from "@/utils/Toast";

@Component({
  components: {
    VAccordion,
    CommentsModal,
  },
})
export default class ContractDocumentItem extends Mixins(
  ContractDocumentsAdminMixin
) {
  @Prop({ required: true }) readonly value!: ContractDocument;
  @Prop({ default: false }) readonly showCategory!: boolean;

  private item = this.value || null;

  get commentsModalRef() {
    return `commentsModal${this.value.id}`;
  }

  get showComments() {
    return this.value?.isDeliverable && this.value.comments?.length;
  }

  @Watch("value")
  onValueChanged(value: ContractDocument) {
    this.item = value;
  }

  get isAdministrative(): boolean {
    return currentUserStore.user.isAdministrative;
  }

  get canCurrentUserDeleteDocument(): boolean {
    return ContractDocument.CanUserDeleteDocument(
      currentUserStore.currentUser,
      this.value.documentType
    );
  }

  get isConsip() {
    return (
      currentUserStore.user.isOccUser || currentUserStore.user.isConsipUser
    );
  }

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  openCommentsModal() {
    (this.$refs[this.commentsModalRef] as any).open(this.value);
  }

  onCommentCreated(comment: Comment) {
    this.item.comments.push(comment);
  }

  get serviceName() {
    return this.value.contractService?.service?.name;
  }

  private async deleteDocument() {
    const [data, errors] = await safeAsync(this.destroyDocument(this.value.id));

    if (errors) {
      showToast("Impossibile eliminare il documento");
      return;
    }

    this.$emit("delete", this.value.id);
  }

  get categoryDescription() {
    return this.item
      ? ContractDocument.categories.find(
          (el: any) => el.id === this.item.category
        ).label
      : "";
  }
}
