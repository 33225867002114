
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import ContractServiceItem from "@/components/ContractService/Item.vue";
import ContractServiceModal from "@/components/ContractService/Modal.vue";
import { Contract, ContractService, ContractUser } from "../../models";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {
    ContractServiceItem,
    ContractServiceModal,
  },
})
export default class ContractServices extends Vue {
  @Ref() readonly contractServiceModal!: any;

  @Prop({ required: true }) readonly contract!: Contract;

  get contractServices() {
    return this.contract?.contractServices;
  }

  createContractService() {
    this.contractServiceModal.create(this.defaultService);
  }

  editContractService(item: ContractService) {
    if (this.isAdministrative) {
      this.contractServiceModal.edit(item);
    }
  }

  onCreated(added: ContractServices) {
    this.$emit("created", added);
  }

  onUpdated(updated: ContractServices) {
    this.$emit("updated", updated);
  }

  onDeleted() {
    this.$emit("deleted");
  }

  get realmId() {
    return this.contract?.initiative?.organization?.realmId;
  }

  get userIds() {
    const ids = this.contract?.contractUsers?.map(
      (o: ContractUser) => o.userId
    );
    return ids.length ? ids : "none";
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get defaultService() {
    const defaultContractService = new ContractService();
    defaultContractService.startsAt = this.contract.startDate;
    defaultContractService.endsAt = this.contract.endDate;
    defaultContractService.contractId = this.contract.id;
    return defaultContractService;
  }
}
