
import { Vue, Component, Mixins, Prop } from "vue-property-decorator";

import TaskAdminMixin, {
  TaskAdminTags,
} from "@/mixins/http/admin/TaskAdminMixin";
import VSvg from "@/components/Common/VSvg.vue";
import VFormGroup from "@/components/Common/VFormGroup.vue";
import { Task, TimeTracking } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import percent from "@/filters/Percent";
import dateTime from "@/filters/DateTime";
import WithErrors from "@/directives/WithErrors";

@Component({
  components: {
    VSvg,
    VFormGroup,
  },
  filters: {
    percent,
    dateTime,
  },
  directives: {
    WithErrors,
  },
})
export default class Progress extends Mixins(TaskAdminMixin) {
  @Prop({ required: true }) readonly value!: Task;
  @Prop({ default: false }) readonly readOnly!: boolean;

  private task: Task | null = null;

  private open = false;

  private totalDays = 0;
  private daysToTrack: number | null = null;

  created() {
    if (this.value) {
      this.task = this.value;
    }

    this.totalDays = this.task.days;
  }

  private invertOpenStatus() {
    this.open = !this.open;
  }

  get httpTag() {
    return TaskAdminTags.TaskTrackTime;
  }

  get progressWidth() {
    if (this.totalDays === 0) {
      return 0;
    }

    return ((this.currentDays * 100) / this.totalDays).toFixed(2);
  }

  get progressStyleWidth() {
    return `width: ${this.progressWidth}%`;
  }

  get timeTrackings() {
    return this.task?.timeTrackings;
  }

  get currentDays() {
    const reducer = (trackedDays: number, currentTrack: TimeTracking) =>
      trackedDays + Math.floor(currentTrack.value);
    return this.task.timeTrackings.reduce(reducer, 0);
  }

  private async load() {
    const [data, errors] = await safeAsync(this.getTask(this.task.id));
    if (data) {
      this.task = data;
    }
  }

  reset() {
    this.open = false;
    this.daysToTrack = null;
  }

  private async trackTimeOnTask() {
    const payload = {
      value: this.daysToTrack,
    };

    const [data, errors] = await safeAsync(
      this.trackTime(this.task.id, payload)
    );
    if (data) {
      this.$emit("updated");
      this.load();
      this.reset();
    }
  }
}
