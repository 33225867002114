
import { Component, Prop, Mixins } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";
import UserMixin, { UserSelectParams } from "@/mixins/http/UserMixin";
import { User } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({ components: { VSelect } })
export default class UserSelect extends Mixins(UserMixin) {
  @Prop() readonly filter!: UserSelectParams;
  @Prop() readonly placeholder!: string;
  @Prop() readonly selected!: string;

  private users: User[] = [];
  private error = false;
  private loading = true;

  get userList(): VSelectOption[] {
    return this.users.map((u) => {
      return { id: u.id, text: u.label };
    });
  }

  mounted(): void {
    this.load();
  }

  async load(): Promise<void> {
    this.loading = true;
    const [data, error] = await safeAsync(this.getUsers(this.filter));

    if (!error) {
      this.users = data;
    } else {
      this.error = true;
    }
    this.loading = false;
  }

  select(id: string): void {
    this.$emit("input", id);
  }
}
