
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { Contract, Initiative, User } from "@/models";
import UserSelect from "@/components/Common/UserSelect.vue";
import { UserSelectParams } from "@/mixins/http/UserMixin";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import ReadonlyDocumentModal from "@/components/Document/ReadOnlyModal.vue";
import Card from "@/components/News/Card.vue";
import { Document } from "../../models";
import { DocumentType } from "@/models/Document";
import { currentUserStore } from "../../store/typed";
import DocumentModal from "@/components/Document/CreateModal.vue";

@Component({
  components: {
    Card,
    UserSelect,
    VModal,
    DocumentModal,
    ReadonlyDocumentModal,
  },
})
export default class ContractRecap extends Vue {
  @Prop({ required: true }) readonly contract!: Contract;
  @Prop({ required: true }) readonly initiative!: Initiative;
  @Prop({ required: true }) readonly projectValue!: string;
  @Prop() readonly manager!: User;

  @Ref() readonly ruacModal!: VModal;

  private userId: string | null = null;

  get lastContractUrl() {
    return this.lastContract?.fileUrl;
  }

  get lastContract() {
    return this.initiative?.executiveContracts[0];
  }

  get lastRequirementPlan() {
    return this.initiative?.requirementsPlans[0];
  }

  get lastRequirementPlanUrl() {
    return this.lastRequirementPlan?.fileUrl;
  }
  get lastOperativePlan() {
    return this.initiative?.operativePlans[0];
  }

  get lastOperativePlanUrl() {
    return this.lastOperativePlan?.fileUrl;
  }

  get lastWorkPlan() {
    return this.initiative?.workPlans[0];
  }

  get managerName() {
    return this.contract?.manager?.fullname() || "-";
  }

  get lastWorkPlanUrl() {
    return this.lastWorkPlan?.fileUrl;
  }

  get initiativeId() {
    return this.initiative?.id;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get userSelectFilter(): UserSelectParams {
    return {
      filter: {
        role: "company_user",
      },
    };
  }

  onDocumentCreated(document: Document) {
    if (document.documentType === DocumentType.WorkPlan) {
      this.initiative.workPlans.unshift(document as any);
    }
  }

  userSelect(id: string): void {
    this.userId = id;
  }

  showRuacModal(): void {
    (this.ruacModal as any).show();
  }

  hide(): void {
    (this.ruacModal as any).hide();
  }

  async modalSave() {
    this.$emit("update-ruac", this.userId);
    this.hide();
  }

  showReadonlyDocumentModal(document: Document) {
    (this.$refs[this.readOnlyDocumentModal] as any).show(document);
  }

  showCreateDocumentModal(type: string) {
    (this.$refs[this.createDocumentModalRef] as any).show(type);
  }

  get createDocumentModalRef() {
    return `create_document_modal`;
  }

  get readOnlyDocumentModal() {
    return `readonly_document_modal`;
  }

  get opModal() {
    return `operative_plan_document_modal`;
  }
}
