
import { ContractServiceAdminTags } from "@/mixins/http/admin/ContractServiceAdminMixin";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import UserSelect from "@/components/User/Select.vue";
import ServiceSelect from "@/components/Service/Select.vue";
import WithErrors from "@/directives/WithErrors";
import BillingTypeSelect from "@/components/Service/BillingTypeSelect.vue";
import { Service } from "../../models";
export interface ContractServiceCreateData {
  id?: string;
  days?: number;
  value?: number;
  startsAt?: Date;
  projectTitle?: string;
  billingType?: string;
  endsAt?: Date;
  assigneeId?: string;
  contractId?: string;
  serviceId?: string;
  executionPlace?: string;
}

@Component({
  directives: {
    WithErrors,
  },
  components: {
    UserSelect,
    BillingTypeSelect,
    ServiceSelect,
  },
})
export default class ContractServiceCreateForm extends Vue {
  @Prop() readonly value!: ContractServiceCreateData;
  @Prop() readonly serviceFilter!: Record<string, any>;
  @Prop() readonly assigneeFilter!: Record<string, any>;

  private item: ContractServiceCreateData = this.value;
  private service: Service | null = null;

  @RegisterHttp(ContractServiceAdminTags.ContractServiceCreate)
  httpRequest!: RequestData;

  get httpTag() {
    return ContractServiceAdminTags.ContractServiceCreate;
  }

  onBillingTypeChanged(key: string) {
    this.$nextTick(() => {
      this.item.billingType = key;
    });
  }

  get fixedPrice() {
    return this.item?.billingType === "fixed_price";
  }

  @Watch("item", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.item);
  }
}
