
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import MilestoneAdminMixin, {
  MilestoneAdminTags,
} from "@/mixins/http/admin/MilestoneAdminMixin";
import { Milestone, Contract } from "@/models";
import DigitalPath from "@/components/Milestone/DigitalPath.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import MilestoneShowModal from "@/components/Milestone/ReadonlyModal.vue";
import { currentUserStore, httpStore } from "../../store/typed";
import MilestoneModal from "@/components/Milestone/Modal.vue";

@Component({
  components: { MilestoneModal, DigitalPath, MilestoneShowModal },
})
export default class ContractMilestones extends Mixins(MilestoneAdminMixin) {
  @Prop({ required: true }) readonly contract!: Contract;
  @Prop({ required: true }) readonly title!: string[];
  @Prop({ required: true }) readonly icon!: string[];
  @Ref() readonly milestoneModal!: any;
  @Ref() readonly milestoneShowModal!: any;
  private milestones: Milestone[] = [];

  created() {
    this.load();
  }

  addMilestone() {
    (this.milestoneModal as any).add(
      this.defaultMilestone,
      this.maxCompletionDate
    );
  }

  onMilestoneCreated(milestone: Milestone) {
    this.milestones.push(milestone);
  }

  onMilestoneDestroyed(id: string) {
    this.milestones = this.milestones.filter((o: Milestone) => o.id !== id);
  }

  onMilestoneUpdated(milestone: Milestone) {
    const index = this.milestones.findIndex(
      (m: Milestone) => m.id === milestone.id
    );
    this.$set(this.milestones, index, milestone);
  }

  editMilestone(milestone: Milestone) {
    if (this.isAdministrative) {
      (this.milestoneModal as any).edit(milestone, this.maxCompletionDate);
    } else {
      (this.milestoneShowModal as any).open(
        milestone,
        this.contract.initiative
      );
    }
  }

  get maxCompletionDate() {
    return this.orderedMilestones.filter((m: Milestone) => !m.completed)[0]
      ?.date;
  }

  get orderedMilestones() {
    return this.milestones.sort((a: Milestone, b: Milestone) => {
      var dateA = new Date(a.date).getTime();
      var dateB = new Date(b.date).getTime();
      return dateA > dateB ? 1 : -1;
    });
  }

  get defaultMilestone() {
    const milestone = new Milestone();
    milestone.initiativeId = this.contract.initiative?.id;
    return milestone;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get contractId() {
    return this.contract?.id;
  }

  get loading() {
    return httpStore.status[MilestoneAdminTags.MilestoneIndex]?.loading;
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  private async load() {
    const filter: any = {
      contract: this.contractId,
    };

    const [data, errors] = await safeAsync(this.getMilestones("all", filter));
    if (data) {
      this.milestones = data;
    }
  }

  onUpdated(milestone: Milestone) {
    const index = this.milestones.findIndex(
      (o: Milestone) => o.id === milestone.id
    );
    this.$set(this.milestones, index, milestone);
  }

  private async update(milestone: Milestone) {
    const [data, errors] = await safeAsync(this.updateMilestone(milestone));

    if (!errors) {
      this.onUpdated(data as Milestone);
    } else {
      showErrorToast("Sì è verificato un errore");
    }
  }
}
