export default function (text: string) {
  if (text) {
    try {
      return `${Number(text).toFixed(2)}%`;
    } catch (e) {
      return "-";
    }
  } else {
    return "-";
  }
}
