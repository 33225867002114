
import { Vue, Component, Prop } from "vue-property-decorator";
import { currentUserStore } from "../../store/typed";

@Component({})
export default class StarRating extends Vue {
  @Prop({ required: true }) readonly value!: number;
  @Prop({ required: true }) readonly starsCount!: number;
  @Prop({ default: false }) readonly disabled!: boolean;

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get isOrganization() {
    return currentUserStore.currentUser?.isOrganizationUser;
  }

  private rating = this.value;

  icon(value: number) {
    return value > this.rating ? "it-star-outline" : "it-star-full";
  }

  rate(vote: number) {
    if (this.isOrganization && !this.disabled) {
      this.rating = vote;
      this.$emit("rated", vote);
    }
  }

  iconClass(value: number) {
    if (!this.isOrganization || this.disabled) {
      return "icon-primary readonly";
    }
    return value > this.rating
      ? "icon-secondary clickable"
      : "icon-primary clickable";
  }
}
