
import { Component, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import MilestoneAdminMixin, {
  MilestoneAdminTags,
} from "@/mixins/http/admin/MilestoneAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Milestone } from "../../models";
import MilestoneForm from "./Form.vue";
import { httpStore } from "../../store/typed";

@Component({
  components: {
    VModal,
    MilestoneForm,
  },
})
export default class MilestoneCreateModal extends Mixins(MilestoneAdminMixin) {
  @Ref() readonly modal!: VModal;

  private milestone: Milestone | null = null;

  private milestonePayload: any | null = null;

  private maxCompletionDate: Date | null = null;

  get actionName() {
    return this.isUpdating ? "Aggiorna" : "Crea";
  }

  private onSubmit() {
    if (this.milestone?.id) {
      this.update();
    } else {
      this.create();
    }
  }

  get isUpdating() {
    return this.milestone?.id;
  }

  get loading() {
    return (
      httpStore.status[MilestoneAdminTags.MilestoneCreate]?.loading ||
      httpStore.status[MilestoneAdminTags.MilestoneUpdate]?.loading ||
      httpStore.status[MilestoneAdminTags.MilestoneDestroy]?.loading
    );
  }

  private async create() {
    const [data, errors] = await safeAsync(
      this.createMilestone(Object.assign({}, this.milestonePayload))
    );

    if (errors) {
      showErrorToast("Errore durante la creazione della milestone");
      return;
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  reset() {
    this.milestone = null;
    this.milestonePayload = null;
  }

  private async destroy() {
    const [data, errors] = await safeAsync(
      this.destroyMilestone(this.milestone.id)
    );

    if (errors) {
      showErrorToast("Errore durante la cancellazione della milestone");
      return;
    }

    this.$emit("destroyed", data.id);
    this.hide();
  }

  private async update() {
    const [data, errors] = await safeAsync(
      this.updateMilestone(this.milestonePayload)
    );

    if (errors) {
      showErrorToast("Errore durante la modifica della milestone");
      return;
    }

    showToast("Aggiornamento avvenuto con successo!");
    this.$emit("updated", data);
    this.hide();
  }

  add(milestone: Milestone, maxCompletionDate: Date) {
    this.assignObject(milestone);
    this.maxCompletionDate = maxCompletionDate;
    this.modal.show();
  }

  assignObject(milestone: Milestone) {
    this.milestone = milestone;
    if (this.milestone) {
      this.milestonePayload = {
        id: milestone.id || null,
        initiativeId: milestone.initiativeId || null,
        description: milestone.description || null,
        date: milestone.date || null,
        completed: milestone.completed || null,
        title: milestone.title || null,
      };
    }
  }

  edit(milestone: Milestone, maxCompletionDate: Date) {
    this.assignObject(milestone);
    this.maxCompletionDate = maxCompletionDate;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
