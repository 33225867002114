
import { Mixins, Component, Prop } from "vue-property-decorator";
import CommentMixin, { CommentMixinTags } from "@/mixins/http/CommentMixin";
import { Comment } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import RequestData from "@/store/types/RequestData";
import { currentUserStore } from "../../store/typed";
@Component({})
export default class CommentItem extends Mixins(CommentMixin) {
  @Prop({ required: true }) readonly value!: Comment;

  @RegisterHttp(CommentMixinTags.CommentRead)
  readonly readRequest!: RequestData;

  private comment: Comment | null = this.value || null;

  get loading() {
    return this.readRequest?.loading;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  async read(): Promise<void> {
    const [data, error] = await safeAsync(this.readComment(this.comment.id));

    if (!error) {
      this.comment = data;
      showToast("Presa visione effettuata!");
    } else {
      showToast("Non è stata presa visione del rilevazione", {
        type: "error",
      });
    }
  }
}
