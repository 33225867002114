
import { Component, Prop, Watch, Mixins, Ref } from "vue-property-decorator";
import ContractServiceSelect from "@/components/ContractService/Select.vue";
import VNumericInput, {
  VNumericInputApplication,
} from "@/components/Common/VNumericInput.vue";
import TaskProgress from "@/components/Task/Progress.vue";
import CommentList from "@/components/Comment/List.vue";
import { Task, TimeTracking } from "@/models";
import TaskAdminMixin, {
  TaskAdminTags,
} from "@/mixins/http/admin/TaskAdminMixin";
import CommentMixin from "@/mixins/http/CommentMixin";
import moment from "moment";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import { currentUserStore } from "@/store/typed";
import { showToast } from "@/utils/Toast";

@Component({
  components: {
    ContractServiceSelect,
    VNumericInput,
    TaskProgress,
    CommentList,
  },
  directives: {
    WithErrors,
  },
})
export default class TaskDetailsForm extends Mixins(
  TaskAdminMixin,
  CommentMixin
) {
  @Prop({ required: true }) readonly value!: Task;
  @Prop({ required: true }) readonly contractId!: string;

  private task: Task | null = null;

  private taskValue: number | null = null;
  private taskDuration = 0;

  @Ref() readonly commentList!: CommentList;
  private commentToInsert = "";

  created() {
    this.initTask(this.value);
  }

  initTask(value: Task) {
    this.task = value;
    this.taskDuration = this.task.days;
    this.taskValue = this.value.value;
    this.startDate = moment(this.task.startDate).format("yyyy-MM-DD");
    this.endDate = moment(this.task.endDate).format("yyyy-MM-DD");
  }

  @Watch("value")
  onValueChanged(value: Task) {
    if (value) {
      this.initTask(value);
    }
  }

  private onTaskValueChanged(value: number) {
    this.taskValue = value;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get contractServiceFilter() {
    return { contract: this.contractId };
  }

  get updateTag() {
    return TaskAdminTags.TaskUpdate;
  }

  get startDate() {
    return moment(this.task.startDate).format("YYYY-MM-DD");
  }

  set startDate(value: string) {
    this.task.startDate = value;
  }

  get endDate() {
    return moment(this.task.endDate).format("YYYY-MM-DD");
  }

  set endDate(value: string) {
    this.task.endDate = value;
  }

  get vNumericInputUsage() {
    return VNumericInputApplication.CURRENCY;
  }

  get contractServiceId() {
    return this.task?.contractServiceId.toString();
  }

  get commentListFilter() {
    if (!this.task) {
      return {};
    }

    return {
      commentable_type: "Task",
      commentable: this.task.id,
    };
  }

  get organizationUser() {
    return currentUserStore.user.isOrganizationUser;
  }

  get conspiUser() {
    return (
      currentUserStore.currentUser.isOccUser ||
      currentUserStore.currentUser.isConsipUser
    );
  }

  get disabled() {
    return this.organizationUser || this.conspiUser;
  }

  get progressPercentage() {
    if (this.task.progress === 0) {
      return 0;
    }
    return (this.task.progress * 100).toFixed(2);
  }

  get currentDays() {
    const reducer = (trackedDays: number, currentTrack: TimeTracking) =>
      trackedDays + Math.floor(currentTrack.value);
    return this.task.timeTrackings.reduce(reducer, 0);
  }

  private closeModal() {
    this.$emit("close");
  }

  private async update() {
    const payload = {
      name: this.task.name,
      start_date: this.startDate,
      end_date: this.endDate,
      description: this.task.description,
      contractServiceId: this.task.contractServiceId,
      days: this.task.days,
      value: this.taskValue,
    };

    const [data, errors] = await safeAsync(
      this.updateTask(this.task.id, payload)
    );

    if (data) {
      this.$emit("close");
    }
  }

  private async deleteCurrent() {
    const [data, errors] = await safeAsync(this.deleteTask(this.task.id));
    this.$emit("delete");
  }

  private async onCommentSubmit() {
    const payload = {
      commentableId: this.task.id,
      commentableType: "Task",
      content: this.commentToInsert,
    };

    const [data, errors] = await safeAsync(this.createComment(payload));
    if (!errors) {
      this.commentList.load();
      this.commentToInsert = "";
      showToast("Commento creato");
    } else {
      showToast("Non è stato creare il commento", {
        type: "error",
      });
    }
  }
}
