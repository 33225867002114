import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import qs from "qs";
import Task from "@/models/Task";

export enum TaskAdminTags {
  TaskIndex = "TaskIndex",
  TaskCreate = "TaskCreate",
  TaskShow = "TaskShow",
  TaskUpdate = "TaskUpdate",
  TaskDestroy = "TaskDestroy",
  TaskTrackTime = "TaskTrackTime",
}

@Component
export default class TaskAdminMixin extends Vue {
  getTasks(page: number | null | string, filter: any) {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: TaskAdminTags.TaskIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Task[]>;
  }

  getTask(id: string): Promise<Task> {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks/${id}`;

    return httpStore.request({
      tag: TaskAdminTags.TaskShow,
      url: url,
      method: "GET",
    }) as Promise<Task>;
  }

  createTask(data: any): Promise<Task> {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks`;

    return httpStore.request({
      tag: TaskAdminTags.TaskCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Task>;
  }

  updateTask(id: string, data: any): Promise<Task> {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks/${id}`;

    return httpStore.request({
      tag: TaskAdminTags.TaskUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Task>;
  }

  deleteTask(id: string): Promise<Task> {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks/${id}`;

    return httpStore.request({
      tag: TaskAdminTags.TaskDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Task>;
  }

  trackTime(id: string, data: any): Promise<Task> {
    const url = `${SD_BASE_ADMIN_API_URL}/tasks/${id}/track_time`;

    return httpStore.request({
      tag: TaskAdminTags.TaskTrackTime,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Task>;
  }
}
