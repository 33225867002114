
import { Component, Prop, Mixins } from "vue-property-decorator";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import VIcon from "@/components/VIcon.vue";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Contract, Initiative, Tag } from "../../models";
import { currentUserStore, tagsStore } from "@/store/typed";
import { InitiativeTag } from "@/models";
import InitiativeTagMixin from "@/mixins/http/InitiativeTagMixin";
import VSvg from "@/components/Common/VSvg.vue";
import TagBadge from "@/components/Common/TagBadge.vue";
import ContractDocuments from "./Documents.vue";
import { ContractDocumentType } from "@/models/ContractDocument";

@Component({
  components: {
    VIcon,
    VSvg,
    TagBadge,
    ContractDocuments
  },
})
export default class InteractiveMap extends Mixins(
  InitiativeMixin,
  InitiativeTagMixin
) {
  @Prop({ required: true }) readonly initiative!: Initiative;
  @Prop({ required: true }) readonly title!: string[];
  @Prop({ required: true }) readonly icon!: string[];
  @Prop({ default: null }) readonly contract?: Contract | null;

  private publishable = this.initiative?.publishable || false;
  private alternativeTitle =
    this.initiative?.alternativeTitle || this.initiative?.name || null;
  private alternativeDescription =
    this.initiative?.alternativeDescription || null;
  private initiativeTags: InitiativeTag[] = [];

  mounted(): void {
    tagsStore.loadTags();
    this.loadInitiativeTags();
  }

  get disabled(): boolean {
    return (
      currentUserStore.user?.isOccUser || currentUserStore.user?.isConsipUser
    );
  }

  get tags(): Tag[] {
    return tagsStore.tags;
  }

  get shareableDocumentType(): string {
    return ContractDocumentType.ShareableDocument;
  }

  get canCurrentUserCreateDocument(): boolean {
    return currentUserStore.currentUser?.isAdministrative || currentUserStore.currentUser?.isOrganizationUser;
  }

  async update(): Promise<void> {
    const payload: Partial<Initiative> = {
      id: this.initiative.id,
      publishable: this.publishable,
      alternativeTitle: this.alternativeTitle || this.initiative.name,
      alternativeDescription: this.alternativeDescription,
    };

    const [data, errors] = await safeAsync(this.updateInitiative(payload));

    if (!errors) {
      showToast("Impostazioni salvate!");
    } else {
      showErrorToast("Sì è verificato un errore");
    }
  }

  async loadInitiativeTags(): Promise<void> {
    const [data, errors] = await safeAsync(
      this.getInitiativeTags(this.initiative.id)
    );
    if (!errors) {
      this.initiativeTags = data;
    }
  }

  tagIsSelected(id: string): boolean {
    return this.initiativeTags.some(
      (initiativeTag) => initiativeTag.tag.id === id
    );
  }

  iconCode(id: string): string {
    if (this.tagIsSelected(id)) {
      return "it-close-big";
    } else {
      return "it-plus";
    }
  }

  toggleTitle(id: string): string {
    if (this.tagIsSelected(id)) {
      return "Rimuovi il tag";
    } else {
      return "Aggiungi il tag";
    }
  }

  toggleTag(id: string): void {
    if (this.tagIsSelected(id)) {
      this.removeTag(id);
    } else {
      this.addTag(id);
    }
  }

  async addTag(id: string): Promise<void> {
    const [data, errors] = await safeAsync(
      this.createInitiativeTag(this.initiative.id, id)
    );
    if (!errors) {
      this.loadInitiativeTags();
    }
  }

  async removeTag(id: string): Promise<void> {
    const initiativeTagId = this.initiativeTags.find(
      (it) => it.tag.id === id
    )?.id;
    if (!initiativeTagId) return;
    const [data, errors] = await safeAsync(
      this.deleteInitiativeTag(initiativeTagId)
    );
    if (!errors) {
      this.loadInitiativeTags();
    }
  }

  onDocumentCreated(document: ContractDocuments): void {
    this.$emit('document-created', document);
  }
}
