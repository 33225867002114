
import { Mixins, Component, Prop } from "vue-property-decorator";
import CommentMixin, { CommentMixinTags } from "@/mixins/http/CommentMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import CommentItem from "@/components/Comment/Item.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { Comment } from "../../models";
@Component({
  components: {
    CommentItem,
  },
})
export default class CommentList extends Mixins(CommentMixin) {
  @Prop({ default: {} }) readonly filter!: any;
  @RegisterHttp(CommentMixinTags.CommentIndex)
  readonly indexRequest!: RequestData;

  private comments: Comment[] = [];
  private error = false;

  get loading() {
    return this.indexRequest?.loading;
  }

  mounted() {
    this.load();
  }

  addItem(comment: Comment) {
    this.comments.unshift(comment);
  }

  async load(): Promise<void> {
    const [data, error] = await safeAsync(this.getComments("all", this.filter));

    if (!error) {
      this.comments = data;
    } else {
      this.error = true;
    }
  }
}
